export const dashboardTabs = [
  {
    id: 0,
    content: '',
    label: 'Brand & Competitive',
    type: 'brand',
    width: '25%',
  },
  {
    id: 1,
    content: '',
    label: 'Industry',
    type: 'industry',
    width: '15%',
  },
  {
    id: 2,
    content: '',
    label: 'People',
    type: 'people',
    width: '15%',
  },
  {
    id: 3,
    content: '',
    label: 'Advance',
    type: 'campaign',
    width: '15%',
  },
  {
    id: 4,
    content: '',
    label: 'Custom',
    type: 'custom',
    width: '15%',
  },
];
// #F4516C
export const dashboardChips = {
  brand: { label: 'Brand', type: 'brand', color: '#F4516C' },
  industry: { label: 'Industry', type: 'industry', color: '#6257E7' },
  advanced: {
    label: 'Advanced',
    type: 'advanced',
    color: '#FFA82D ',
    // '#FFA82D'
  },
  people: { label: 'People', type: 'people', color: '#12CCA7' },
  custom: { label: 'Custom', type: 'custom', color: '#22B9FF' },
  campaign: {
    label: 'adv-campaign',
    type: 'campaign',
    color: '#FFA82D',
    // '#FFA82D',
  },
  authorimpact: {
    label: 'adv-authorimpact',
    type: 'authorimpact',
    color: '#FFA82D',
    // '#6257E7',
  },
  sentiments: {
    label: 'adv-sentiments',
    type: 'sentiments',
    color: '#FFA82D',
    // '#22B9FF',
  },
  congruence: {
    label: 'adv-congruence',
    type: 'congruence',
    color: '#FFA82D',
    // '#F4516C',
  },
  primpact: {
    label: 'adv-primpact',
    type: 'primpact',
    color: '#FFA82D',
    // '#12CCA7',
  },
};
