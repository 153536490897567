import authorImpact from './author impact.svg';
import campaignMonitor from './campaign_monitor.svg';
import coolColumn from './cool_colmn.svg';
import coverage from './covrege.svg';
import journalists from './journalists.svg';
import mediaType from './media-type.svg';
import messageCongruence from './message_congruence_Sov.svg';
import prImpact from './pr-impact.svg';
import reachOverTime from './reach.svg';
import sentiments from './sentiments.svg';
import topAuthor from './top_author-mentions.svg';
import topSources from './top_sources.svg';

import topTheme from './top_themes.svg';
import worldMap from './world_map.svg';
// placeholders
import lineGraph from './ph-line-graph.svg';
import areaGraph from './ph-area-graph.svg';
import barGraph from './ph-bar-graph.svg';
import detailsGraph from './ph-details-graph.svg';
import mediaTypePie from './media-type-pie.svg';
import resultOvertime from './result_over_time.svg';
import wordCloud from './word-cloud.svg';
import geographicalBreakdown from './geographical-breakdown.svg';
import volumeAnalysis from './volume-analysis.svg';
import sentimentAnalysis from './sentiment-analysis.svg';
import sentimentByThemes from './sentiments-by-theme.svg';
import sentimentOverTime from './sentiments-over-time.svg';
import coverageOverTime from './coverage-over-time.svg';
import mediaTypeBar from './media-type-bar.svg';
import articleSentiment from './article-sentiment.svg';
import coverageByJournalist from './coverage-by-journalist.svg';
import outletBreakDown from './outlet-breakdown.svg';
import topSourceBySentiment from './top-source-sentiment.svg';
import stackedBar from './stackedbar.svg';

export const widgetImg = {
  authorImpact,
  campaignMonitor,
  coolColumn,
  coverage,
  journalists,
  mediaType,
  messageCongruence,
  prImpact,
  reachOverTime,
  sentiments,
  topAuthor,
  topTheme,
  worldMap,
  mediaTypePie,
  resultOvertime,
  wordCloud,
  topSources,
  geographicalBreakdown,
  volumeAnalysis,
  sentimentAnalysis,
  sentimentByThemes,
  sentimentOverTime,
  coverageOverTime,
  mediaTypeBar,
  articleSentiment,
  coverageByJournalist,
  outletBreakDown,
  topSourceBySentiment,
  stackedBar,
};

export const widgetPlaceHolders = {
  lineGraph,
  areaGraph,
  barGraph,
  detailsGraph,
};
