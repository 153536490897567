export const USStates = [
  { stateNumber: '01', stateName: 'Alabama' },
  { stateNumber: '02', stateName: 'Alaska' },
  { stateNumber: '03', stateName: 'Arizona' },
  { stateNumber: '04', stateName: 'Arkansas' },
  { stateNumber: '05', stateName: 'California' },
  { stateNumber: '06', stateName: 'Colorado' },
  { stateNumber: '07', stateName: 'Connecticut' },
  { stateNumber: '08', stateName: 'Delaware' },
  { stateNumber: '09', stateName: 'Florida' },
  { stateNumber: '10', stateName: 'Georgia' },
  { stateNumber: '11', stateName: 'Hawaii' },
  { stateNumber: '12', stateName: 'Idaho' },
  { stateNumber: '13', stateName: 'Illinois' },
  { stateNumber: '14', stateName: 'Indiana' },
  { stateNumber: '15', stateName: 'Iowa' },
  { stateNumber: '16', stateName: 'Kansas' },
  { stateNumber: '17', stateName: 'Kentucky' },
  { stateNumber: '18', stateName: 'Louisiana' },
  { stateNumber: '19', stateName: 'Maine' },
  { stateNumber: '20', stateName: 'Maryland' },
  { stateNumber: '21', stateName: 'Massachusetts' },
  { stateNumber: '22', stateName: 'Michigan' },
  { stateNumber: '23', stateName: 'Minnesota' },
  { stateNumber: '24', stateName: 'Mississippi' },
  { stateNumber: '25', stateName: 'Missouri' },
  { stateNumber: '26', stateName: 'Montana' },
  { stateNumber: '27', stateName: 'Nebraska' },
  { stateNumber: '28', stateName: 'Nevada' },
  { stateNumber: '29', stateName: 'New Hampshire' },
  { stateNumber: '30', stateName: 'New Jersey' },
  { stateNumber: '31', stateName: 'New Mexico' },
  { stateNumber: '32', stateName: 'New York' },
  { stateNumber: '33', stateName: 'North Carolina' },
  { stateNumber: '34', stateName: 'North Dakota' },
  { stateNumber: '35', stateName: 'Ohio' },
  { stateNumber: '36', stateName: 'Oklahoma' },
  { stateNumber: '37', stateName: 'Oregon' },
  { stateNumber: '38', stateName: 'Pennsylvania' },
  { stateNumber: '39', stateName: 'Rhode Island' },
  { stateNumber: '40', stateName: 'South Carolina' },
  { stateNumber: '41', stateName: 'South Dakota' },
  { stateNumber: '42', stateName: 'Tennessee' },
  { stateNumber: '43', stateName: 'Texas' },
  { stateNumber: '44', stateName: 'Utah' },
  { stateNumber: '45', stateName: 'Vermont' },
  { stateNumber: '73', stateName: 'Virginia' },
  { stateNumber: '47', stateName: 'Washington' },
  { stateNumber: '51', stateName: 'West Virginia' },
  { stateNumber: '54', stateName: 'Wisconsin' },
  { stateNumber: '56', stateName: 'Wyoming' },
];
