import React from 'react';

const GreenFace = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons">
        <rect
          id="Icon"
          x="0.0507812"
          y="0.515625"
          width="20"
          height="20"
          fill="white"
        ></rect>
        <path
          id="Vector"
          d="M10.041 1.59375C5.11658 1.59375 1.12891 5.59035 1.12891 10.5147C1.12891 15.4391 5.11658 19.4357 10.041 19.4357C14.9743 19.4357 18.9709 15.4391 18.9709 10.5147C18.9709 5.59035 14.9743 1.59375 10.041 1.59375ZM10.0499 17.6515C6.10681 17.6515 2.9131 14.4578 2.9131 10.5147C2.9131 6.57166 6.10681 3.37795 10.0499 3.37795C13.993 3.37795 17.1867 6.57166 17.1867 10.5147C17.1867 14.4578 13.993 17.6515 10.0499 17.6515ZM13.1722 9.62263C13.9127 9.62263 14.5104 9.02493 14.5104 8.28449C14.5104 7.54404 13.9127 6.94634 13.1722 6.94634C12.4318 6.94634 11.8341 7.54404 11.8341 8.28449C11.8341 9.02493 12.4318 9.62263 13.1722 9.62263ZM6.92754 9.62263C7.66798 9.62263 8.26569 9.02493 8.26569 8.28449C8.26569 7.54404 7.66798 6.94634 6.92754 6.94634C6.1871 6.94634 5.5894 7.54404 5.5894 8.28449C5.5894 9.02493 6.1871 9.62263 6.92754 9.62263ZM10.0499 15.4213C12.1285 15.4213 13.8948 14.1188 14.6085 12.2989H5.49127C6.20494 14.1188 7.9713 15.4213 10.0499 15.4213Z"
          fill="#00B929"
        ></path>
      </g>
    </svg>
  );
};

export default GreenFace;
