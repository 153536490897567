export const initialValues = {
  x: undefined,
  y: undefined,
  width: 0,
  height: 0,
  fontSize: 15,
  padding: { left: 0, top: 0, right: 0, bottom: 0 },
  textwrap: false,
  xLabelAlignment: 25,
  yLabelAlignment: 35,
  xAxisLabelBreatingSpace: 10,
  duration: 1000,
  gutterSpace: 10,
  graphTopPadding: 10,
  dasharray: 4,
  gridLineStrokeWidth: 2,
};

export const colorBox = [
  '#614CFF',
  '#8A74FF',
  '#D16BFD',
  '#9955F4',
  '#4337A0',
  '#85ECE9',
  '#32CBC8',
  '#35C684',
  '#FA8750',
  '#EFB73E',
  '#FFE566',
  '#EB4A84',
  '#6D5E7B',
  '#656B8A',
  '#B4BAD9',
];
