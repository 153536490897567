export const wcData = {
  coffee: 25,
  morning: 20,
  'team meeting': 12,
  'email communication': 15,
  'project deadline': 14,
  'office lunch break': 7,
  'client presentation': 13,
  'research report': 8,
  'colleague collaboration': 9,
  'manager strategy planning': 8,
  'innovation technology': 9,
  'creative brainstorming': 6,
  'productive work': 10,
  'success challenge': 7,
  'efficiency improvement': 5,
  'work-life balance': 6,
  'goal achievement': 11,
  'client satisfaction': 13,
  'team building': 10,
  'professional development': 8,
  'workplace culture': 9,
  'leadership skills': 7,
  'career advancement': 6,
  'company values': 8,
  'communication skills': 7,
  'customer service excellence': 6,
  'strategic planning': 10,
  'employee engagement': 9,
  'time management': 11,
  'innovative solutions': 12,
  'project management': 14,
  'productivity tips': 15,
  'coffee break': 25,
  'office environment': 7,
  'team collaboration': 9,
  'creative thinking': 10,
  'deadline pressure': 13,
  'leadership qualities': 8,
  'workplace challenges': 7,
  'job satisfaction': 6,
  'career growth': 9,
  'teamwork success': 8,
  'workplace diversity': 7,
  'communication effectiveness': 6,
  'company culture': 11,
  'professionalism in the workplace': 10,
  'team leadership': 9,
  'employee motivation': 7,
  'workplace innovation': 6,
  'strategic goals': 8,
  'customer relationship management': 10,
  'workplace efficiency': 9,
  'time-saving strategies': 7,
  'workplace productivity': 8,
  'work-life integration': 6,
};

export default function parseText(text) {
  text = text.split('\n');
  return text;
}
