import React from 'react';

const EditIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 16C1.0875 16 0.734375 15.8531 0.440625 15.5594C0.146875 15.2656 0 14.9125 0 14.5V3.5C0 3.08178 0.145833 2.72375 0.4375 2.42592C0.729167 2.12808 1.08333 1.98611 1.5 2H8.70833L7.20833 3.5H1.5V14.5H12.5V8.79167L14 7.29167V14.5C14 14.9125 13.8531 15.2656 13.5594 15.5594C13.2656 15.8531 12.9125 16 12.5 16H1.5ZM5 11V7.8125L12.375 0.4375C12.5278 0.284722 12.6944 0.173611 12.875 0.104167C13.0556 0.0347222 13.2396 0 13.4271 0C13.6271 0 13.8177 0.0347222 13.999 0.104167C14.1802 0.173611 14.3468 0.283216 14.4988 0.43298L15.5625 1.5C15.7153 1.65278 15.8264 1.82071 15.8958 2.00379C15.9653 2.18688 16 2.37311 16 2.5625C16 2.75224 15.9651 2.93881 15.8954 3.12221C15.8256 3.30561 15.7147 3.47321 15.5625 3.625L8.1875 11H5ZM6.5 9.5H7.5625L12.375 4.6875L11.8542 4.14583L11.3125 3.625L6.5 8.4375V9.5Z"
        fill="#1C1B1F"
      />
    </svg>
  );
};

export default EditIcon;
