import React from 'react';
import Proptypes from 'prop-types';

const Edit2 = ({ size = '1.563rem' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6293_302492)">
        <g clipPath="url(#clip1_6293_302492)">
          <path
            d="M20.7676 11.9453V17.4453C20.7649 18.1738 20.4744 18.8718 19.9592 19.3869C19.4441 19.9021 18.7461 20.1927 18.0176 20.1953H7.01758C6.28904 20.1927 5.5911 19.9021 5.07595 19.3869C4.56079 18.8718 4.27021 18.1738 4.26758 17.4453L4.26758 6.44531C4.27021 5.71678 4.56079 5.01883 5.07595 4.50368C5.5911 3.98852 6.28904 3.69795 7.01758 3.69531H12.5176C12.7157 3.6979 12.9049 3.77775 13.045 3.91785C13.1851 4.05794 13.265 4.24721 13.2676 4.44531C13.265 4.64342 13.1851 4.83268 13.045 4.97278C12.9049 5.11287 12.7157 5.19272 12.5176 5.19531H7.01758C6.68606 5.19531 6.36812 5.32701 6.13369 5.56143C5.89927 5.79585 5.76758 6.11379 5.76758 6.44531V17.4453C5.76758 17.7768 5.89927 18.0948 6.13369 18.3292C6.36812 18.5636 6.68606 18.6953 7.01758 18.6953H18.0176C18.3491 18.6953 18.667 18.5636 18.9015 18.3292C19.1359 18.0948 19.2676 17.7768 19.2676 17.4453V11.9453C19.2676 11.7464 19.3466 11.5556 19.4872 11.415C19.6279 11.2743 19.8187 11.1953 20.0176 11.1953C20.2165 11.1953 20.4073 11.2743 20.5479 11.415C20.6886 11.5556 20.7676 11.7464 20.7676 11.9453ZM9.68758 11.5953L16.3776 4.90531C16.8041 4.50787 17.3682 4.2915 17.9511 4.30179C18.534 4.31207 19.0902 4.54821 19.5024 4.96045C19.9147 5.37269 20.1508 5.92884 20.1611 6.51174C20.1714 7.09465 19.955 7.65879 19.5576 8.08531L12.8676 14.7753C12.7271 14.916 12.5364 14.9951 12.3376 14.9953H10.2676C10.1687 14.9967 10.0706 14.9782 9.97898 14.941C9.88738 14.9037 9.80416 14.8486 9.73425 14.7786C9.66434 14.7087 9.60914 14.6255 9.57193 14.5339C9.53471 14.4423 9.51623 14.3442 9.51758 14.2453V12.1253C9.50417 11.9334 9.56503 11.7436 9.68758 11.5953ZM10.9676 13.4953H12.0276L18.4976 7.02531C18.6206 6.88781 18.6886 6.7098 18.6886 6.52531C18.6886 6.34083 18.6206 6.16282 18.4976 6.02531C18.357 5.88486 18.1663 5.80597 17.9676 5.80597C17.7688 5.80597 17.5782 5.88486 17.4376 6.02531L10.9676 12.4353V13.4953Z"
            fill="#5C5E60"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6293_302492">
          <rect
            width="1.5rem"
            height="1.5rem"
            fill="white"
            transform="translate(0.517578 0.195312)"
          />
        </clipPath>
        <clipPath id="clip1_6293_302492">
          <rect
            width="1.5rem"
            height="1.5rem"
            fill="white"
            transform="translate(0.517578 0.195312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

Edit2.propTypes = {
  size: Proptypes.string,
  color: Proptypes.string,
};

export default Edit2;
