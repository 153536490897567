import Image from './image.svg';
import XCircle from './x-circle.svg';
import NpCustomis from './np_customize.svg';
import LoginBgImage from './loginImg/loginBgImage.svg';
import Rectangle1 from './loginImg/Rectangle1.svg';
import Rectangle2 from './loginImg/Rectangle2.svg';
import Rectangle3 from './loginImg/Rectangle3.svg';
import DarkLogo from './bg/DarkLogo.svg';
import LoginBG from './loginImg/loginBG.svg';
import Group from './loginImg/Group.svg';
import Rect1 from './loginImg/Rect1.png';
import Rect2 from './loginImg/Rect2.png';
import Rect3 from './loginImg/Rect3.png';
import BackGround from './bg/background.png';
import LoginBg2 from './loginImg/LoginBg2.png';
import Cancel from './cancel.png';
import Check from './check.png';
import ArticlePlaceholder from './articlePlaceholder.svg';
import LogoWithText from './bg/logoWithText.svg';
import ArticleImageNAImg from './NA for no image.svg';
export const Img = {
  Image,
  XCircle,
  NpCustomis,
  LoginBgImage,
  Rectangle1,
  Rectangle2,
  Rectangle3,
  DarkLogo,
  LoginBG,
  Group,
  Rect1,
  Rect2,
  Rect3,
  BackGround,
  LoginBg2,
  Cancel,
  Check,
  ArticlePlaceholder,
  LogoWithText,
  ArticleImageNAImg,
};
