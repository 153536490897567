import React from 'react';

const ToolBarArrowUp = () => {
  return (
    <>
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Path 322"
          d="M8.08368 2.1123L13.1467 7.58731H9.54668V12.9873H6.62068V7.58731H3.02068L8.08368 2.1123ZM8.08368 0.237305C7.87426 0.238128 7.67299 0.318565 7.52068 0.462305L0.808678 7.7703C0.692549 7.8727 0.610953 8.00852 0.575088 8.15913C0.539224 8.30974 0.550848 8.46776 0.608367 8.6115C0.665885 8.75524 0.766478 8.87766 0.896341 8.96195C1.0262 9.04625 1.17897 9.08829 1.33368 9.0823H5.12068V13.7323C5.12068 13.9312 5.1997 14.122 5.34035 14.2626C5.481 14.4033 5.67177 14.4823 5.87068 14.4823H10.2957C10.4946 14.4823 10.6854 14.4033 10.826 14.2626C10.9667 14.122 11.0457 13.9312 11.0457 13.7323V9.0823H14.8337C14.9884 9.08829 15.1412 9.04625 15.271 8.96195C15.4009 8.87766 15.5015 8.75524 15.559 8.6115C15.6165 8.46776 15.6281 8.30974 15.5923 8.15913C15.5564 8.00852 15.4748 7.8727 15.3587 7.7703L8.64668 0.462305C8.49437 0.318565 8.2931 0.238128 8.08368 0.237305Z"
          fill="#5C5E60"
        />
      </svg>
    </>
  );
};

export default ToolBarArrowUp;
