import React from 'react';

const NewsletterArticlesArrowUp = () => {
  return (
    <>
      <svg
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.75 0L4.75 6.125L6.9375 3.9375L8 5L4 9L0 5L1.0625 3.9375L3.25 6.125L3.25 0H4.75Z"
          fill="#1C1B1F"
        />
      </svg>
    </>
  );
};

export default NewsletterArticlesArrowUp;
