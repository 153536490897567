import React from 'react';

const DashboardIcon = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.152344"
        y="0.921875"
        width="40"
        height="40"
        rx="20"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M21.6974 24.1506H27.1701C27.4675 24.1506 27.7528 24.2687 27.9631 24.479C28.1733 24.6893 28.2915 24.9746 28.2915 25.272V27.9377C28.2915 28.2351 28.1733 28.5203 27.9631 28.7306C27.7528 28.9409 27.4675 29.059 27.1701 29.059H21.6974C21.4 29.059 21.1148 28.9409 20.9045 28.7306C20.6942 28.5203 20.576 28.2351 20.576 27.9377V25.272C20.576 24.9746 20.6942 24.6893 20.9045 24.479C21.1148 24.2687 21.4 24.1506 21.6974 24.1506ZM13.1351 20.4079H16.7365C17.0339 20.4079 17.3191 20.5261 17.5294 20.7364C17.7397 20.9466 17.8578 21.2319 17.8578 21.5293V27.9377C17.8578 28.2351 17.7397 28.5203 17.5294 28.7306C17.3191 28.9409 17.0339 29.059 16.7365 29.059H13.1351C12.8377 29.059 12.5524 28.9409 12.3421 28.7306C12.1319 28.5203 12.0137 28.2351 12.0137 27.9377V21.5293C12.0137 21.2319 12.1319 20.9466 12.3421 20.7364C12.5524 20.5261 12.8377 20.4079 13.1351 20.4079ZM21.6974 12.7812H27.1701C27.4675 12.7812 27.7528 12.8994 27.9631 13.1097C28.1733 13.32 28.2915 13.6052 28.2915 13.9026V20.311C28.2915 20.6084 28.1733 20.8936 27.9631 21.1039C27.7528 21.3142 27.4675 21.4324 27.1701 21.4324H21.6974C21.4 21.4324 21.1148 21.3142 20.9045 21.1039C20.6942 20.8936 20.576 20.6084 20.576 20.311V13.9026C20.576 13.6052 20.6942 13.32 20.9045 13.1097C21.1148 12.8994 21.4 12.7812 21.6974 12.7812ZM13.1351 12.7812H16.7365C17.0339 12.7812 17.3191 12.8994 17.5294 13.1097C17.7397 13.32 17.8578 13.6052 17.8578 13.9026V16.5683C17.8578 16.8657 17.7397 17.1509 17.5294 17.3612C17.3191 17.5715 17.0339 17.6897 16.7365 17.6897H13.1351C12.8377 17.6897 12.5524 17.5715 12.3421 17.3612C12.1319 17.1509 12.0137 16.8657 12.0137 16.5683V13.9026C12.0137 13.6052 12.1319 13.32 12.3421 13.1097C12.5524 12.8994 12.8377 12.7812 13.1351 12.7812Z"
        stroke="#8676FF"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default DashboardIcon;
