import React from 'react';
import PropTypes from 'prop-types';

const SmileIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask
        id="mask0_748_969"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <rect width={width} height={height} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_748_969)">
        <path
          d="M12.75 9.25C13.0972 9.25 13.3924 9.12847 13.6354 8.88542C13.8785 8.64236 14 8.34722 14 8C14 7.65278 13.8785 7.35764 13.6354 7.11458C13.3924 6.87153 13.0972 6.75 12.75 6.75C12.4028 6.75 12.1076 6.87153 11.8646 7.11458C11.6215 7.35764 11.5 7.65278 11.5 8C11.5 8.34722 11.6215 8.64236 11.8646 8.88542C12.1076 9.12847 12.4028 9.25 12.75 9.25ZM7.25 9.25C7.59722 9.25 7.89236 9.12847 8.13542 8.88542C8.37847 8.64236 8.5 8.34722 8.5 8C8.5 7.65278 8.37847 7.35764 8.13542 7.11458C7.89236 6.87153 7.59722 6.75 7.25 6.75C6.90278 6.75 6.60764 6.87153 6.36458 7.11458C6.12153 7.35764 6 7.65278 6 8C6 8.34722 6.12153 8.64236 6.36458 8.88542C6.60764 9.12847 6.90278 9.25 7.25 9.25ZM10 14C10.8333 14 11.6007 13.7847 12.3021 13.3542C13.0035 12.9236 13.5556 12.3403 13.9583 11.6042H6.04167C6.44444 12.3403 6.99653 12.9236 7.69792 13.3542C8.39931 13.7847 9.16667 14 10 14ZM10.0058 18C8.9047 18 7.86806 17.7917 6.89583 17.375C5.92361 16.9583 5.07292 16.3854 4.34375 15.6562C3.61458 14.9271 3.04167 14.0767 2.625 13.105C2.20833 12.1334 2 11.0952 2 9.99046C2 8.88571 2.20833 7.85069 2.625 6.88542C3.04167 5.92014 3.61458 5.07292 4.34375 4.34375C5.07292 3.61458 5.92332 3.04167 6.89496 2.625C7.86661 2.20833 8.90481 2 10.0095 2C11.1143 2 12.1493 2.20833 13.1146 2.625C14.0799 3.04167 14.9271 3.61458 15.6562 4.34375C16.3854 5.07292 16.9583 5.92169 17.375 6.89008C17.7917 7.85849 18 8.89321 18 9.99425C18 11.0953 17.7917 12.1319 17.375 13.1042C16.9583 14.0764 16.3854 14.9271 15.6562 15.6562C14.9271 16.3854 14.0783 16.9583 13.1099 17.375C12.1415 17.7917 11.1068 18 10.0058 18ZM10 16.5C11.8056 16.5 13.3403 15.8681 14.6042 14.6042C15.8681 13.3403 16.5 11.8056 16.5 10C16.5 8.19444 15.8681 6.65972 14.6042 5.39583C13.3403 4.13194 11.8056 3.5 10 3.5C8.19444 3.5 6.65972 4.13194 5.39583 5.39583C4.13194 6.65972 3.5 8.19444 3.5 10C3.5 11.8056 4.13194 13.3403 5.39583 14.6042C6.65972 15.8681 8.19444 16.5 10 16.5Z"
          fill="#096841"
        />
      </g>
    </svg>
    // <svg
    //   width={width}
    //   height={height}
    //   viewBox="0 0 14 14"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <rect width="14" height="14" fill="#C3C8DC" />
    //   <g id="All Dashboards" clipPath="url(#clip0_6293_303031)">
    //     <rect
    //       width="1512"
    //       height="2541"
    //       transform="translate(-1372.5 -1031.5)"
    //       fill="#ECEFF3"
    //     />
    //     <g id="Group 290109">
    //       <g id="RIGHT CONTINER">
    //         <path
    //           id="WHITE BG"
    //           d="M-447.461 568.012H102.539V-878.438C102.539 -881.2 100.3 -883.438 97.5391 -883.438H-442.461C-445.222 -883.438 -447.461 -881.2 -447.461 -878.438V568.012Z"
    //           fill="white"
    //         />
    //         <g id="ARTICLES">
    //           <g id="Frame 289923" clipPath="url(#clip1_6293_303031)">
    //             <g id="Frame 289922">
    //               <g id="Frame 289935" clipPath="url(#clip2_6293_303031)">
    //                 <g id="Group 289696" filter="url(#filter0_d_6293_303031)">
    //                   <rect
    //                     width="550"
    //                     height="278"
    //                     transform="translate(-447.461 -207.84)"
    //                     fill="white"
    //                   />
    //                   <g id="Frame 290248">
    //                     <g id="Frame 290247">
    //                       <g id="Frame 290244">
    //                         <g id="Frame 2239">
    //                           <g id="Group 289667">
    //                             <g id="Frame 290250">
    //                               <g
    //                                 id="Layer_1"
    //                                 clipPath="url(#clip3_6293_303031)"
    //                               >
    //                                 <g id="angry_emoticon">
    //                                   <path
    //                                     id="Vector"
    //                                     d="M7.03963 12.7472C10.4011 12.7472 13.1261 10.0222 13.1261 6.66072C13.1261 3.29924 10.4011 0.574219 7.03963 0.574219C3.67815 0.574219 0.953125 3.29924 0.953125 6.66072C0.953125 10.0222 3.67815 12.7472 7.03963 12.7472Z"
    //                                     fill="#E0FBE6"
    //                                   />
    //                                   <path
    //                                     id="Vector_2"
    //                                     d="M7.03935 0.160156C3.44949 0.160156 0.539062 3.07059 0.539062 6.66044C0.539062 10.2503 3.44949 13.1607 7.03935 13.1607C10.6292 13.1607 13.5396 10.2503 13.5396 6.66044C13.5396 3.07059 10.6292 0.160156 7.03935 0.160156ZM7.03935 12.3721C3.88955 12.3721 1.32708 9.80966 1.32708 6.65987C1.32708 3.51008 3.88955 0.948173 7.03935 0.948173C10.1891 0.948173 12.7516 3.51065 12.7516 6.66044C12.7516 9.81023 10.1891 12.3727 7.03935 12.3727V12.3721Z"
    //                                     fill="#00B929"
    //                                   />
    //                                   <path
    //                                     id="Vector_3"
    //                                     d="M9.50358 8.58633C8.14474 9.94518 5.9342 9.94518 4.57535 8.58633C4.42127 8.43226 4.42127 8.18323 4.57535 8.02915C4.72886 7.87507 4.97846 7.87507 5.13253 8.02915C6.18436 9.08041 7.89514 9.08041 8.9464 8.02915C9.02315 7.9524 9.12436 7.91373 9.22499 7.91373C9.32562 7.91373 9.42683 7.9524 9.50358 8.02915C9.65766 8.18323 9.65766 8.43226 9.50358 8.58633Z"
    //                                     fill="#00B929"
    //                                   />
    //                                   <path
    //                                     id="Vector_4"
    //                                     d="M9.2264 5.67545C9.59629 5.67545 9.89615 5.37559 9.89615 5.00569C9.89615 4.6358 9.59629 4.33594 9.2264 4.33594C8.8565 4.33594 8.55664 4.6358 8.55664 5.00569C8.55664 5.37559 8.8565 5.67545 9.2264 5.67545Z"
    //                                     fill="#00B929"
    //                                   />
    //                                   <path
    //                                     id="Vector_5"
    //                                     d="M4.85335 5.63639C5.22325 5.63639 5.52311 5.33653 5.52311 4.96663C5.52311 4.59674 5.22325 4.29688 4.85335 4.29688C4.48345 4.29688 4.18359 4.59674 4.18359 4.96663C4.18359 5.33653 4.48345 5.63639 4.85335 5.63639Z"
    //                                     fill="#00B929"
    //                                   />
    //                                 </g>
    //                               </g>
    //                             </g>
    //                           </g>
    //                         </g>
    //                       </g>
    //                     </g>
    //                   </g>
    //                 </g>
    //               </g>
    //             </g>
    //           </g>
    //         </g>
    //       </g>
    //     </g>
    //   </g>
    //   <defs>
    //     <filter
    //       id="filter0_d_6293_303031"
    //       x="-447.461"
    //       y="-207.84"
    //       width="550"
    //       height="279"
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity="0" result="BackgroundImageFix" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy="1" />
    //       <feComposite in2="hardAlpha" operator="out" />
    //       <feColorMatrix
    //         type="matrix"
    //         values="0 0 0 0 0.92549 0 0 0 0 0.937255 0 0 0 0 0.960784 0 0 0 1 0"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in2="BackgroundImageFix"
    //         result="effect1_dropShadow_6293_303031"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in="SourceGraphic"
    //         in2="effect1_dropShadow_6293_303031"
    //         result="shape"
    //       />
    //     </filter>
    //     <clipPath id="clip0_6293_303031">
    //       <rect
    //         width="1512"
    //         height="2541"
    //         fill="white"
    //         transform="translate(-1372.5 -1031.5)"
    //       />
    //     </clipPath>
    //     <clipPath id="clip1_6293_303031">
    //       <rect
    //         width="550"
    //         height="2244.03"
    //         fill="white"
    //         transform="translate(-447.461 -835.188)"
    //       />
    //     </clipPath>
    //     <clipPath id="clip2_6293_303031">
    //       <rect
    //         width="550"
    //         height="2244"
    //         fill="white"
    //         transform="translate(-447.461 -835.188)"
    //       />
    //     </clipPath>
    //     <clipPath id="clip3_6293_303031">
    //       <rect
    //         width="13"
    //         height="13"
    //         fill="white"
    //         transform="translate(0.539062 0.160156)"
    //       />
    //     </clipPath>
    //   </defs>
    // </svg>
  );
};
SmileIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SmileIcon;
