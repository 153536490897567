import React from 'react';

const NewsletterSettingsIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
      >
        <path
          d="M9.66683 15.9792C9.50016 16.0347 9.34739 16.0104 9.2085 15.9062C9.06961 15.8021 9.00016 15.6667 9.00016 15.5V10.5C9.00016 10.3333 9.06961 10.1979 9.2085 10.0938C9.34739 9.98958 9.49322 9.96528 9.646 10.0208C10.1877 10.2014 10.7363 10.3264 11.2918 10.3958C11.8474 10.4653 12.4168 10.5 13.0002 10.5C13.5696 10.5 14.1321 10.4653 14.6877 10.3958C15.2432 10.3264 15.7918 10.2014 16.3335 10.0208C16.5002 9.96528 16.6529 9.98958 16.7918 10.0938C16.9307 10.1979 17.0002 10.3333 17.0002 10.5V15.5C17.0002 15.6667 16.9307 15.8021 16.7918 15.9062C16.6529 16.0104 16.5002 16.0347 16.3335 15.9792C15.7918 15.7986 15.2432 15.6736 14.6877 15.6042C14.1321 15.5347 13.5696 15.5 13.0002 15.5C12.4307 15.5 11.8682 15.5347 11.3127 15.6042C10.7571 15.6736 10.2085 15.7986 9.66683 15.9792ZM6.396 16L5.93766 13.625C5.61822 13.5 5.30919 13.3542 5.01058 13.1875C4.71197 13.0208 4.43072 12.8264 4.16683 12.6042L1.87516 13.375L0.270996 10.6042L2.0835 9.02083C2.05572 8.85417 2.03489 8.6875 2.021 8.52083C2.00711 8.35417 2.00016 8.18056 2.00016 8C2.00016 7.81944 2.00711 7.64583 2.021 7.47917C2.03489 7.3125 2.05572 7.14583 2.0835 6.97917L0.270996 5.375L1.87516 2.60417L4.16683 3.39583C4.43072 3.17361 4.71197 2.97917 5.01058 2.8125C5.30919 2.64583 5.61822 2.5 5.93766 2.375L6.396 0H9.60433L10.0627 2.375C10.3821 2.5 10.6911 2.64583 10.9897 2.8125C11.2884 2.97917 11.5696 3.17361 11.8335 3.39583L14.1252 2.625L15.7293 5.39583L13.9168 6.97917C13.9446 7.14583 13.9654 7.3125 13.9793 7.47917C13.9932 7.64583 14.0002 7.81944 14.0002 8C14.0002 8.16667 13.9932 8.33333 13.9793 8.5C13.9654 8.66667 13.9446 8.83333 13.9168 9H10.8335C10.8891 8.84722 10.9307 8.6875 10.9585 8.52083C10.9863 8.35417 11.0002 8.18056 11.0002 8C11.0002 7.16667 10.7085 6.45833 10.1252 5.875C9.54183 5.29167 8.8335 5 8.00016 5C7.16683 5 6.4585 5.29167 5.87516 5.875C5.29183 6.45833 5.00016 7.16667 5.00016 8C5.00016 8.75 5.23975 9.40278 5.71891 9.95833C6.19808 10.5139 6.79183 10.8472 7.50016 10.9583V16H6.396Z"
          fill="#1C1B1F"
        />
      </svg>
    </>
  );
};

export default NewsletterSettingsIcon;
