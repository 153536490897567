import styled from 'styled-components';
export const SwitchToDesktopView = styled.div`
  /* Default styles for the component */
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SwitchToDesktopViewText = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;
